import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Markdown from 'markdown-to-jsx'

import Layout from '../components/layout'
import SEO from '../components/seo'
import PageHero from '../components/hero/page'
import InfoCard from '../components/infocard'
import Contact from '../components/contact'
import HorizontalForm from '../components/forms/horizontal'

import styles from '../styles/page.module.scss'

const IndexPage = () => (
  <StaticQuery
    query={graphql`
      query ThankYouContentsQuery {
        currentPage: allContentYaml(filter: { url: { eq:"/thank-you" } }) {
          edges {
            node {
              title
              description
              heroLeadLine
              heroLine
              heroPoints
            }
          }
        }

        globalSettings: allContentYaml(filter: {
          type: { eq: "GlobalSettings" }
        }) {
          edges {
            node {
              callToActionTitle
            }
          }
        }

        allFile(filter: { extension: { eq: "png" } }) {
          edges {
            node {
              name
              childImageSharp {
                fixed(width:128, height: 128) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      const page = data.currentPage.edges[0].node

      return (
        <Layout>
          <SEO
            title={ page.title }
            description={ page.description }
            canonical="https://motorinjuryhelpline.com.au/thank-you"
            robots={ [
              'noindex',
              'nofollow',
              'nosnippet',
              'noarchive',
              'noydir'
            ] }
          />

          <PageHero
            leadLine={ page.heroLeadLine }
            heroLine={ page.heroLine }
            heroPoints={ page.heroPoints }
            disableContact
          />
          <Contact />

        </Layout>
      )
    }}
  />
)

export default IndexPage
